<div class="container p-3" style="margin-bottom: 30px">
  <div class="d-step-2 gap-container row"
  >
    <div
      [ngClass]="{
        'detalhes-card col-sm-12 col-md-12':
          auth.currentUserValue.tipoUsuarioId === 1,
        'detalhes-card col-sm-12 col-md-12 col-lg-12':
          auth.currentUserValue.tipoUsuarioId !== 1
      }"
    >
    <!-- Bloco Nome Itens DEPENDENTE FALSE -->
      <div class="container-card-profile">
        <div ckass="areaAvatar">
          <app-avatar [foto]="(BeneficiarioSelecionado|async)?.foto"></app-avatar>
        </div>

        <!--Bloco Nome e Itens-->
        <div class="w-100 align-start">
          <div class="card-profile-info mt-2">
            <div>
              <p class="h3">{{ (BeneficiarioSelecionado|async)?.nome | uppercase }}</p>
            </div>
            <div class="row">
              <div class="col-xl-3 col-lg">
                <small>Data de Nascimento</small>
                <div>
                  <img
                    src="assets/imagens/icons/page-detalhes/data-nascimento.svg"
                    alt="Data de nascimento"
                  />
                  {{ (BeneficiarioSelecionado|async)?.dataNascimento | date: "dd/MM/yyyy" }}
                </div>
              </div>
              <div class="col-xl-4 col-lg">
                <small>Número do Cartão</small>
                <div>
                  <img
                    src="assets/imagens/icons/page-detalhes/numero-cartao.svg"
                    alt="Número da carteirinha"
                  />
                  {{ (BeneficiarioSelecionado|async)?.carteirinha | mask: "0000.0000.000000.00-0" }}
                </div>
              </div>
              <div class="col-xl-5 areaItens col-lg">
                <small>nome da mãe</small>
                <div
                  [ngClass]="{
                    txtMae: auth.currentUserValue.tipoUsuarioId !== 2
                  }"
                  matTooltip="{{ (BeneficiarioSelecionado|async)?.nomeMae }}"
                >
                  <img
                    src="assets/imagens/icons/page-detalhes/nome-mae.svg"
                    alt="nome da mae"
                  />
                  {{ (BeneficiarioSelecionado|async)?.nomeMae || "não informado" }}
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <!--Fim Bloco Nomes e Itens-->
      </div>

      <!--Bloco Dependente-->
      <div
        *ngIf="
        (beneficiarios$|async)?.dependentes?.length > 0 &&
          auth.currentUserValue.tipoUsuarioId == 1
        "
        class="container-card-dependente"
      >
        <div>
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-8 areatxtdependentes">
              <div>
                <img
                  src="assets/imagens/icons/page-detalhes/icon-dependentes.svg"
                  alt="Ícone de Dependentes"
                />
                <i>Meus exames ou dos meus dependentes </i>
              </div>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-6">
              <select
                class="form-select selectDependentes"
                (change)="selecionaExamesPorUserHandle($event.target.value)"
              >
                <optgroup
                  style="font-size: 12px; font-weight: normal"
                  label="Exibir exames de: "
                >
                  <option
                    value="{{(beneficiarios$ | async).id}}"
                  >
                    {{
                      !!(beneficiarios$ | async)
                        ? (beneficiarios$ | async).nome
                        : " --- "
                    }}
                  </option>
                  <option
                    *ngFor="let item of (beneficiarios$ | async).dependentes"
                    value="{{item.id}}"
                  >
                    {{ item.nome }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <p>
            Obs: Você só pode acessar os exames dos dependentes menores de 18
            anos.
          </p>
        </div>
      </div>
    </div>

    <!--Bloco ACEITE ONINIT PAGINA-->
    <!-- <div
      *ngIf="auth.currentUserValue.tipoUsuarioId === 1"
      class="aceite-card col-md-11 col-lg-4 col-sm-12 row {{ pep }}"
    >
      <div class="col-md-9 col-lg-7 col-sm-8 col-7">
        <div *ngIf="pep === alert.WARNING" class="areaTextoCard">
          <img src="assets/imagens/icone-termo-aceite.svg"  alt="ícone termo de aceite" />
          <p>Beneficiario não possui o <br /><span>Termo de Aceite</span></p>
        </div>

        <div *ngIf="pep === alert.SUCCESS" class="areaTextoCard">
          <img src="assets/imagens/icone-termo-aceite.svg" alt="ícone termo de aceite" />
          <p>Beneficiario possui o <br /><span>Termo de Aceite</span></p>
        </div>
        {{ pep === alert.ALERT ? "Termo em analise" : "" }}
      </div>
      <div class="col-lg-5 col-md-3 col-sm-4 col-5">
        <button
          class="btn mat-elevation-z4"
          *ngIf="pep === alert.WARNING"
          [routerLink]="['/aceite']"
        >
        <mat-icon>description</mat-icon>
          Aceitar Termo
        </button>

        <div *ngIf="pep === alert.SUCCESS">
          <button (click)="visualizarTermo()" class="btn mat-elevation-z4">
            <i class="material-icons">description</i> Visualizar
          </button>
        </div>
      </div>
    </div> -->
  </div>
</div>

<app-visualizar-pdf
  *ngIf="visualizarModalDetalhes"
  [base64]="base64"
></app-visualizar-pdf>

<app-lista-exames-beneficiario *ngIf="beneficiarioCompleto">
</app-lista-exames-beneficiario>
